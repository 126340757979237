import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createGamePlayed } from '../../api/gamesApi';
import { createPlayer, searchPlayer } from '../../api/playersApi';
import { IPlayer } from '../../types/PlayerInterface';

const InitScreen = () => {
  const [player, setPlayer] = useState<IPlayer>({
    name: '',
    phone: '',
    amountToPay: '',
    email: '',
  });
  // const sitio = process.env.REACT_APP_CURRENT_SITE === 'Encinos';

  return (
    <section className="large-container container-fluid">
      <div className="loader">
        <span className="loader-spin"></span>
      </div>
      <div className="row">
        <div className="col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <h1>Inicio del juego</h1>
          </section>
          <section className="game-form col-sm-5 col-6 mx-auto box-height">
            <div className="game-form__row">
              <label htmlFor="name" className="form-label">
                Nombre completo<small>*</small>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Ingresa tu nombre completo"
                value={player.name || ''}
                onChangeCapture={(evt) => {
                  setPlayer({
                    ...player,
                    name: (evt.target as HTMLTextAreaElement).value || '',
                  });
                }}
              />
            </div>
            <div className="game-form__row">
              <label htmlFor="phone" className="form-label">
                Teléfono <small>*</small>
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                placeholder="5512345678"
                inputMode="tel"
                value={player.phone || ''}
                pattern="(\d{10})|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)"
                onChangeCapture={(evt) => {
                  setPlayer({
                    ...player,
                    phone: (evt.target as HTMLTextAreaElement).value || '',
                  });
                }}
              />
            </div>
            {/* Condición para mostrar costo en Encinos 
            {sitio ? (
              <></>
            ) : (
              <div className="game-form__row">
                <label htmlFor="phone" className="form-label">
                  Ingresar Donativo <small>*</small>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="donate"
                  placeholder="$0"
                  inputMode="numeric"
                  value={player.amountToPay || ''}
                  pattern="(\d{10})|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)"
                  onChangeCapture={(evt) => {
                    setPlayer({
                      ...player,
                      amountToPay:
                        (evt.target as HTMLTextAreaElement).value || '',
                    });
                  }}
                />
              </div>
            )} */}
          </section>
          <div className="control-box">
            <Link to="/homepage" className="btn btn--type2">
              Cancelar
            </Link>
            {/* Condición para mostrar costo en Encinos */}
            <button
              className="btn btn--type1"
              onClick={(e) => {
                e.preventDefault();
                // eslint-disable-next-line prefer-const
                let valid = Object.keys({
                  name: player.name,
                  phone: player.phone,
                }).every((p) => player[p as keyof IPlayer] !== '');
                /* if (!sitio) {
                  valid = player.amountToPay !== '';
                } */
                if (!valid) {
                  return Swal.fire('Oops', 'Faltan campos por llenar', 'error');
                }
                return Swal.fire({
                  title: '¿Son correctos los datos?',
                  html: `
                    <h5><strong>${player.name}</strong></h5>
                    <p><strong>${player.phone}</strong></p>
                    <p><strong>${player.email}</strong></p>
                  `,
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Si, confirmar',
                })
                  .then((result) => {
                    console.log(result);
                    if (result.isConfirmed) {
                      const loader = document.querySelector('.loader');
                      loader?.classList.add('active');
                      if (result.isConfirmed && player.email) {
                        return searchPlayer(0, 1, '', {
                          email: player.email,
                        });
                      }
                    } else {
                      window.location.replace(`/init`);
                    }
                  })
                  .then((resp) => {
                    /**
                     * si se encuentra al jugador,
                     * se crea juego
                     */
                    if (resp && (resp.docs || []).length) {
                      return createGamePlayed({
                        playerId: resp.docs[0]._id || '',
                        score: 180,
                        winner: false,
                        isCash: false,
                        amountToPay: Number(player.amountToPay || 0),
                      });
                    }
                  })
                  .then((resp) => {
                    if (!resp) {
                      /**
                       * Si no,
                       * se crea al jugador
                       */
                      return createPlayer({ ...player });
                    } else {
                      window.location.replace(`/rules/${resp?._id}`);
                    }
                  })
                  .then((resp) => {
                    if (resp) {
                      /**
                       * Después de crear al jugador,
                       * se crea el juego
                       */
                      return createGamePlayed({
                        playerId: resp._id || '',
                        score: 180,
                        winner: false,
                        isCash: false,
                        amountToPay: Number(player.amountToPay || 0),
                      });
                    }
                  })
                  .then((gamePlayed) => {
                    if (gamePlayed) {
                      return window.location.replace(
                        `/rules/${gamePlayed?._id}`
                      );
                    }
                  });
              }}
            >
              Iniciar
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InitScreen;
