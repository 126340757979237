import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyScreen = () => {
  // const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <header
          className={scroll ? 'header-nav col-12 active' : 'header-nav col-12'}
        >
          <button onClick={() => navigate(-1)} className="btn btn--simple-back">
            <span className="material-icons">arrow_back</span> <p>Regresar</p>
          </button>
        </header>
        <div className="contain-top col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <h1>Políticas de Privacidad y Protección de Datos Personales</h1>
          </section>
          <section className="content-info col-sm-6 col-7 mx-auto mb40">
            <article>
              <p><strong>Operadora Deportiva A Holy Car, S.A.PI., de C.V.,</strong> referido en adelante como “AHC”, con domicilio en avenida Homero 229, Piso Master Suite, colonia Polanco, alcaldía de Miguel Hidalgo, CP 11560, Ciudad de México, México, es un prestador de servicios diversos enfocado en la administración, organización y operación de torneos deportivos.</p>

              <h4>¿Qué es el Aviso de Privacidad?</h4>

              <p>Documento a disposición del titular de los datos personales de forma física, electrónica o en cualquier formato generado por el responsable, con el objeto de informarle los propósitos del tratamiento de los mismos a partir del momento en el cual se recaben sus datos personales.</p>

              <p>A través de este documento, se le comunica al titular la información que se recaba respecto de su persona y la finalidad de su obtención, así como la posibilidad de ejercer los derechos de acceso, rectificación, cancelación y oposición (derechos ARCO) y la forma de ejercerlos.</p>

              <h4>¿Qué son los datos personales?</h4>

              <p>Cualquier información concerniente a una persona física identificada o identificable.</p>

              <h4>Objeto</h4>

              <p>El presente Aviso de Privacidad, explica cómo se tratan y protegen los datos personales que sean recabados en nuestros sistemas y formatos, dándole la seguridad de que los datos serán almacenados en plataformas seguras y su tratamiento se llevará de conformidad con lo dispuesto en los artículos 16, 18, 21, 22, 25, 26 y 65 de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados.</p>

              <h4>¿Para qué fines se recabarán sus datos personales?</h4>

              <ol>
                <li>Verificar y confirmar su identidad, así como la autenticidad de la información que nos proporciona para contar con un registro que permita identificar con precisión quién desea inscribirse a algún torneo;</li>
                <li>Acreditar los requisitos necesarios para inscribirse a un torneo;</li>
                <li>Integrar expedientes y bases de datos necesarias para la debida administración, organización y operación de los torneos en que Usted pueda inscribirse;</li>
                <li>Realizar publicaciones de imágenes y videos de Usted participando en cualquiera de nuestros torneos y/o recibiendo cualquier premio;</li>
                <li>Hacer entrega de los premios a los participantes ganadores; y</li>
                <li>Mantener una base histórica con fines estadísticos.</li>
              </ol>
              <p>Así mismo, su información personal será utilizada para proveer los servicios de administración, organización y operación de los torneos deportivos en los que decida participar, realizar evaluaciones periódicas de nuestros servicios a efecto de mejorar la calidad de los mismos, mantenerlo informado sobre cambios en los servicios, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.</p>

              <p>Para efectos de lo anterior, podremos solicitar datos de identificación personal, datos de contacto, entre otros.</p>

              <h4>¿Qué datos personales se recabarán?</h4>

              <p>Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, se utilizarán, de manera enunciativa más no limitativa, los siguientes datos personales:</p>

              <p>Datos de identificación personal: nombre completo, domicilio, teléfono (fijo y móvil), correo electrónico, firma autógrafa, clave del Registro Federal de Contribuyentes (RFC); cuenta bancaria y CLABE, perfil de cuentas sociales, entre otros.</p>

              <p>Los datos que se recaben a través de los sistemas y formatos, en ningún caso son datos sensibles.</p>

              <h4>Transferencia de datos personales</h4>

              <p>Sus datos personales no serán transferidos, difundidos, ni distribuidos, salvo lo señalado en el artículo 22 de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados o en el caso que exista consentimiento expreso de su parte por medio escrito o por un medio de autenticación similar. Sólo tendrán acceso a esta información el titular de los datos, sus representantes legales, los operadores de campo, los terceros que provean el servicio de entrega de premios,  y los servidores públicos facultados para ello.</p>

              <p>En caso necesario su información personal puede ser tratada o transferida dentro o fuera del país a empresas relacionadas, subsidiarias o afiliadas o controladoras de “AHC”, así como a proveedores y prestadores de servicios terceros que se encuentren relacionados con nuestros torneos.</p>

              <h4>¿Dónde puedo ejercer mis derechos ARCO?</h4>

              <p>Usted tiene derecho a conocer qué datos personales nos ha proporcionado, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal cuando esté incompleta, sea inexacta, inadecuada o excesiva (Rectificación); que se elimine de nuestros registros o bases de datos cuando considere que su tratamiento contraviene lo dispuesto por la ley o porque dejó de ser necesaria para el cumplimiento de la finalidad o finalidades de dicha base (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>

              <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos. Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta; que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa; así como oponerse al uso de sus datos personales para fines específicos. Estos derechos se conocen como derechos ARCO.</p>

              <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva por escrito presentado directamente en nuestra oficina. El área correspondiente le informará por correo electrónico, al correo que usted nos proporcione, en un plazo máximo de quince días contados a partir de la fecha en que se recibió la solicitud, la resolución adoptada, a efecto de que, si resulta procedente, se haga efectiva dentro de los diez días siguientes a la fecha en que se comunica la respuesta. Tratándose de solicitudes de acceso a datos personales, procederá la entrega previa acreditación de la identidad del solicitante o representante legal, según corresponda.</p>

              <h4>Cambios al Aviso de Privacidad</h4>

              <p>Derivado de nuevos requerimientos legales, de nuevas disposiciones administrativas o de otra índole, el presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones; en este sentido, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente, a través de su sitio electrónico.</p>

              <p>Si usted no manifiesta su oposición al tratamiento, uso o transferencia de sus datos personales, se entiende que usted nos ha otorgado su consentimiento para ello.</p>

            </article>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PrivacyScreen;
