import { encode, ParsedUrlQueryInput } from 'querystring';
import api from './index';
import { IUser, IUserPaginate } from '../types/UserInterface';
import { IUserInvitationPaginate } from '../types/UserInvitationInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const signUp = async (user: Partial<IUser>) => {
  const response = await api.post<IUser>('/users', user);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const signIn = async (user: Partial<IUser>) => {
  const response = await api.post<IUser>('/users/login', user);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const forgotPass = async (email: string) => {
  const response = await api.post<IUser>('/users/forgot', { email });
  return response;
};

export const recoverPass = async (user: Partial<IUser>, id: string) => {
  const response = await api.post<IUser>(`/users/recover/${id}`, user);
  return response;
};

export const getUsers = async (
  offset: number,
  limit: number,
  searchText: string,
  token: string
) => {
  const response = await api.get<IUserPaginate>(
    `/users?${encode({
      limit,
      offset,
      searchText,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getUser = async (userId: string, token: string) => {
  const response = await api.get<IUser>(`/users/${userId}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateUser = async (
  userId: string,
  user: Partial<IUser>,
  token: string
) => {
  const response = await api.patch<IUser>(`/users/${userId}`, user, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getUserInvitations = async (
  offset: number,
  limit: number,
  token: string
) => {
  const response = await api.get<IUserInvitationPaginate>(
    `/users-invitation?${encode({
      limit,
      offset,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const newUserInvitations = async (token: string) => {
  const response = await api.post<IUser>(
    '/users/invitation',
    {},
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
