import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getGamePlayedById } from '../../api/gamesApi';
import { IGamePlayed } from '../../types/GamesInterface';
import { getSession } from '../../utils/helpers';

const PayScreen = () => {
  const navigate = useNavigate();
  const { gamePlayedId } = useParams();
  const [gamePlayed, setGamePlayed] = useState<Partial<IGamePlayed>>({});
  const sitio = process.env.REACT_APP_CURRENT_SITE === 'Encinos';

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Oops', `Hubo un error ${errorMessage}`, 'error');
  };

  const getGamePlayed = async (id: string, token: string) => {
    try {
      const response = await getGamePlayedById(id, token);
      if (response) {
        setGamePlayed(response);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    if (gamePlayedId) {
      const session = getSession();
      if (session) {
        getGamePlayed(gamePlayedId, session.token);
      }
    }
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <p>Paso 2/3</p>
            <h1>Hora de pagar</h1>
          </section>
          <div className="pay-info box-height">
            {/* Condición para mostrar costo en Encinos */}
            <>
              <p>
                El <strong>{`${sitio ? 'costo' : 'costo'}`} por juego</strong>{' '}
                es por jugador y no es reembolsable
              </p>
            </>
            <p className="pay__cost">
              $
              {`${
                gamePlayed.amountToPay
                  ? gamePlayed.amountToPay.toFixed(2)
                  : '0.00' || '0.00'
              }`}
            </p>

            <p className="text-center mb8 mt32">
              <strong>
                Al pagar aceptas los{' '}
                <Link target={'_blank'} to="/terminos-y-condiciones">
                  términos y condiciones
                </Link>{' '}
                y{' '}
                <Link target={'_blank'} to="/aviso-de-privacidad">
                  aviso de privacidad
                </Link>
                .
              </strong>
            </p>
            {/* Condición para mostrar costo en Encinos */}
            <div className="pay-box mb-4">
              <Link
                to={`/pay-efectivo/${gamePlayedId}`}
                className="btn btn--type3"
              >
                Pagar en efectivo
              </Link>
              <button
                className="btn btn--type1"
                onClick={(evt) => {
                  evt.preventDefault();
                  return Swal.fire({
                    icon: 'info',
                    title: '¡Listo!',
                    html: `Revisa y actualiza la terminal para completar tu pago.`,
                    confirmButtonText: 'De acuerdo',
                  }).then(() => {
                    navigate(`/success/${gamePlayed._id}`);
                  });
                }}
              >
                <div>
                  <p> Pagar con tarjeta</p>
                  <p className="small-text">
                    <em>Aceptamos VISA, MASTERCARD y AMEX</em>
                  </p>
                </div>
              </button>
            </div>
            <p>
              <strong>Pide ayuda del administrador</strong> para hacer el
              proceso de pago
            </p>
          </div>
          <div className="control-box">
            <Link to="/rules" className="btn btn--type2">
              regresar
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PayScreen;
