import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../api/usersApi';
import { saveSession } from '../../utils/helpers';

const LoginAdminScreen = () => {
  const navigate = useNavigate();
  const [user, setUserData] = useState({
    email: '',
    password: '',
  });
  const signInUser = () => {
    const valid = Object.keys(user).every((key) => (user as any)[key] !== '');
    if (!valid) {
      Swal.fire('Oops!', 'Faltan campos por llenar', 'warning');
    }
    return signIn(user)
      .then((signUser) => {
        saveSession(signUser);
        window.location.replace('/homepage');
      })
      .catch(() => {
        Swal.fire('Oops!', 'Usuario y/o contraseña incorrectos', 'warning');
      });
  };
  return (
    <section className="large-container container-fluid">
      <div className="row">
        <header className="header-nav col-12">
          <button onClick={() => navigate(-1)} className="btn btn--simple-back">
            <span className="material-icons">arrow_back</span> <p>Regresar</p>
          </button>
        </header>
        <div className="contain-top col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <h1>Iniciar sesión de administrador</h1>
          </section>
          <form
            onSubmit={(evt) => {
              evt.preventDefault();
              signInUser();
            }}
          >
            <section className="game-form col-sm-5 col-6 mx-auto box-height">
              <div className="game-form__row">
                <label htmlFor="name" className="form-label">
                  Correo elecrónico
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="micorreo@mail.com"
                  inputMode="email"
                  onChangeCapture={(evt) =>
                    setUserData({
                      ...user,
                      email: (evt.target as HTMLTextAreaElement).value || '',
                    })
                  }
                />
              </div>
              <div className="game-form__row">
                <label htmlFor="name" className="form-label">
                  Contraseña
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="********"
                  inputMode="text"
                  onChangeCapture={(evt) =>
                    setUserData({
                      ...user,
                      password: (evt.target as HTMLTextAreaElement).value || '',
                    })
                  }
                />
              </div>
              <div className="control-box mt40">
                <button type="submit" className="btn btn--type1">
                  Iniciar
                </button>
              </div>
            </section>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginAdminScreen;
