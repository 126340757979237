import React from 'react';
import { Link, useParams } from 'react-router-dom';

const RulesScreen = () => {
  const { gamePlayedId } = useParams();

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <p>Paso 1/3</p>
            <h1>Reglas del juego</h1>
          </section>
          <div className="rules-box box-height col-sm-10 col-9 mx-auto mb32">
            <div className="rule-box__row">
              <h4>Torneos “A Hole in One”</h4>
              <ul>
                <li>
                Todo participante que desee inscribirse, deberá realizar su registro en el kiosko proporcionado por AHC, proporcionando todos los datos requeridos, y efectuando el pago de inscripción por <strong>cualquier método de pago aceptado en dicho kiosko</strong>.
                </li>
                <li>El participante inscrito <strong>realizará un solo golpe</strong> conforme las reglas contenidas en el reglamento de Golf vigente y aceptado por el campo en el que se lleva a cabo dicho torneo, utilizando el equipo debido, y desde la posición indicada por AHC, exclusivamente en el hoyo designado para el torneo.</li>
                <li>El participante competirá contra todos los inscritos en su torneo, y únicamente el primero en conseguir un <strong>“hoyo en uno”</strong>, conforme lo dispuesto en el reglamento de Golf vigente y aceptado por el campo en el que se lleva a cabo el torneo, será acreedor al premio disponible para dicho torneo.</li>
                <li><strong>Los premios serán los publicados en el kiosko</strong>, previo a la inscripción del participante. No aplican cambios ni devoluciones.</li>
               
              </ul>
            </div>
           
            <div className="rule-box__row">
            <h4>Torneos “Oh Yes!”</h4>
             <ul>
              <li>Todo participante que desee inscribirse, <strong>deberá realizar su registro en el kiosko</strong> proporcionado por AHC, proporcionando todos los datos requeridos, y efectuando el pago de inscripción por cualquier método de pago aceptado en dicho kiosko.
              </li>
              <li>El participante inscrito <strong>realizará un solo golpe</strong> conforme las reglas contenidas en el reglamento de Golf vigente y aceptado por el campo en el que se lleva a cabo dicho torneo, utilizando el equipo debido, y desde la posición indicada por AHC, exclusivamente en el hoyo designado para el torneo.</li>
              <li>	El participante competirá contra todos los inscritos en su torneo, una vez concluido el torneo, <strong>el participante cuyo golpe hubiere dejado su pelota más cerca del hoyo</strong>, de conformidad con el reglamento de Golf vigente y aceptado por el campo en el que se lleva a cabo el torneo, será acreedor al premio disponible para dicho torneo.
              </li>
              <li><strong>Los premios serán los publicados en el kiosko</strong>, previo a la inscripción del participante. No aplican cambios ni devoluciones.</li>
             </ul>
             <p><em>Las presentes reglas se encuentran subordinadas a lo dispuesto en los <Link target={'_blank'} to="/terminos-y-condiciones">Términos y condiciones</Link> de AHC. Todas las decisiones del Comité Especial son definitivas.</em></p>
            </div>
          </div>
          <div className="control-box">
            <Link to="/init" className="btn btn--type2">
              regresar
            </Link>
            <Link to={`/pay/${gamePlayedId}`} className="btn btn--type1">
              Continuar
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RulesScreen;
