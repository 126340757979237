import React from 'react';
import { Link } from 'react-router-dom';

const ErrorScreen = () => {
  // const navigate = useNavigate();

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <h1>Oops! ocurrió un error</h1>
          </section>
          <section className="game-info col-sm-6 col-7 mx-auto">
            <figure className="game-info__icon">
              <img src="assets/images/icon/error.svg" alt="Success" />
            </figure>
            <article>
              <p>
                Mencionale a nuestro asociado que te oriente sobre este error o
                vuelve a intentarlo
              </p>
            </article>
          </section>
          <div className="control-box">
            <Link to="/" className="btn btn--type1">
              Intentar de nuevo
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorScreen;
