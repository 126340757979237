import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { updateGamePlayed } from '../../api/gamesApi';
import { signIn } from '../../api/usersApi';
import { getSession } from '../../utils/helpers';

const PayEfectivoScreen = () => {
  const session = getSession();
  const navigate = useNavigate();
  const { gamePlayedId } = useParams();
  const [password, setPassword] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <p>Paso 2/3</p>
            <h1>Pago en efectivo</h1>
          </section>
          <div className="pay-info box-height">
            <p className="pay__cost mb40">
              <strong>Total a pagar </strong>$300.00
            </p>
            <p>
              <strong>Pide ayuda del administrador</strong> para hacer el
              proceso de pago
            </p>
            <div className="pay-code col-6 mx-auto">
              <div className=" row">
                <div className="col">
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <p className="text-center mb8 mt32">
              <strong>
                Al pagar aceptas los{' '}
                <Link to="/terminos-y-condiciones">términos y condiciones</Link>{' '}
                y <Link to="/aviso-de-privacidad">aviso de privacidad</Link>.
              </strong>
            </p>
          </div>
          <div className="control-box">
            <Link to="/pay" className="btn btn--type2">
              Regresar
            </Link>
            <button
              className="btn btn--type1"
              onClick={(e) => {
                e.preventDefault();
                return signIn({ email: session?.email, password })
                  .then(() => {
                    if (gamePlayedId) {
                      updateGamePlayed(
                        gamePlayedId,
                        { isCash: true },
                        session?.token || ''
                      ).then(() => {
                        navigate(`/success`);
                      });
                    }
                  })
                  .catch(() => {
                    Swal.fire(
                      'Oops!',
                      'Usuario y/o contraseña incorrectos',
                      'warning'
                    );
                  });
              }}
            >
              Pagar
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PayEfectivoScreen;
