import Cookies from 'js-cookie';
import { IUser } from '../types/UserInterface';

const COOKIE_NAME = 'HolyCarAppSessionCooke';

export const saveSession = async (user: Partial<IUser>) => {
  if (user) {
    Cookies.set(COOKIE_NAME, JSON.stringify({ ...user }));
  }
};

export const getSession = () => {
  const data = Cookies.get(COOKIE_NAME); // sin sesión
  return data ? (JSON.parse(data) as IUser) : null;
};

export const updateSession = (user: IUser) => {
  Cookies.remove(COOKIE_NAME);
  Cookies.set(COOKIE_NAME, JSON.stringify({ ...user }));
};

export const deleteSession = () => {
  Cookies.remove(COOKIE_NAME);
};
