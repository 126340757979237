import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format, getUnixTime, subDays } from 'date-fns';
import { searchGamePlayed } from '../../api/gamesApi';
import { IIGamePlayedPaginate } from '../../types/GamesInterface';
import { IPlayer } from '../../types/PlayerInterface';

const LandingScreen = () => {
  const [scroll, setScroll] = useState(false);
  const [games, setGames] = useState<IIGamePlayedPaginate>();

  const getGamesPlayedDocs = async () => {
    try {
      const now = new Date();
      console.log(now.getFullYear(), now.getMonth(), now.getDate());
      const createdAt = getUnixTime(
        subDays(new Date(now.getFullYear(), now.getMonth(), now.getDate()), 30)
      );
      const result = await searchGamePlayed(0, 10, '', {
        createdAt: `${createdAt}`,
      });
      if (result) {
        if (result.docs.length) {
          const gamesPlayed = result.docs.sort((a, b) =>
            a.score > b.score ? -1 : 1
          );
          setGames({
            ...result,
            docs: gamesPlayed,
          });
        }
      }
    } catch (error) {
      console.log('getGamesPlayedDocs', error);
    }
  };

  useEffect(() => {
    getGamesPlayedDocs();
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <header
          className={
            scroll
              ? 'header-nav header--content col-12 active'
              : 'header-nav header--content col-12'
          }
        >
          <nav className="main-nav">
            <a href="https://holy-car.com/" className="btn btn--type2">
              CLUB CAMPESTRE
            </a>
            <a
              href="https://bosquereal.holy-car.com/"
              className="btn btn--type2"
            >
              REAL DEL BOSQUE
            </a>
            <a
              href="https://clubgolfmexico.holy-car.com/"
              className="btn btn--type2"
            >
              CLUB DE GOLF MX
            </a>
            <Link to="/login" className="btn btn--type1">
              Iniciar sesión
            </Link>
          </nav>
        </header>
        <div className="contain-top  large-container__background large-container__col col-12">
          <section className="score-mini">
            <div className="score-mini__date">
              <p>Hoy {`${format(new Date(), 'dd . MMM . yyy')}`}</p>
              <h4>Score Board</h4>
            </div>
            <div className="score-mini__user-win">
              <div className="badge badge--color1">Ganador</div>
              <h3>
                1ER LUGAR .{' '}
                {games?.docs.length
                  ? `${(games.docs[0].playerId as IPlayer).name}`
                  : ``}
              </h3>
              <p className="sore-mini__user-win-name">
                Score {games?.docs.length ? `${games.docs[0].score}` : ``}
              </p>
            </div>
            <div className="mini-table">
              {(games?.docs || []).map((game, key) => {
                const { playerId, score } = game;
                const { name } = playerId as IPlayer;
                if (key === 0) {
                  return <div key={key}></div>;
                }
                return (
                  <div className="mini-table__row" key={key}>
                    <p>
                      <strong>{`${key + 1}°`} Lugar</strong>
                    </p>
                    <p>{`${name}`}</p>
                    <p className="mini-table__score">
                      <strong>Score . {`${score}`}</strong>
                    </p>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default LandingScreen;
