import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format, getUnixTime, subDays } from 'date-fns';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { searchGamePlayed } from '../../api/gamesApi';
import { IIGamePlayedPaginate } from '../../types/GamesInterface';
import { IPlayer } from '../../types/PlayerInterface';

const HomeScreen = () => {
  const [games, setGames] = useState<IIGamePlayedPaginate>();

  const holeInOne = process.env.REACT_APP_HOLE_IN_ONE;
  const oYes = process.env.REACT_APP_O_YES;
  const gameAmount = process.env.REACT_APP_GAME_AMOUNT;

  const getGamesPlayedDocs = async () => {
    try {
      const now = new Date();
      console.log(now.getFullYear(), now.getMonth(), now.getDate());
      const createdAt = getUnixTime(
        subDays(new Date(now.getFullYear(), now.getMonth(), now.getDate()), 30)
      );
      const result = await searchGamePlayed(0, 10, '', {
        createdAt: `${createdAt}`,
        score: 180,
      });
      if (result) {
        if (result.docs.length) {
          const gamesPlayed = result.docs.sort((a, b) =>
            a.score > b.score ? -1 : 1
          );
          setGames({
            ...result,
            docs: gamesPlayed,
          });
        }
      }
    } catch (error) {
      console.log('getGamesPlayedDocs', error);
    }
  };

  useEffect(() => {
    getGamesPlayedDocs();
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <div className="large-container__background large-container__col col-sm-6 col-12">
          <section className="score-mini ">
            <div className="score-mini__date">
              <p>Hoy {`${format(new Date(), 'dd . MMM . yyy')}`}</p>
              <h4>Score Board</h4>
            </div>
            <div className="score-mini__user-win">
              <div className="badge badge--color1">Ganador</div>

              <div className="mini-table__row">
                <p>
                  <strong> 1° Lugar</strong>
                </p>
                <p>
                  {games?.docs.length
                    ? `${(games.docs[0].playerId as IPlayer).name}`
                    : ``}
                </p>
                <p className="mini-table__score">
                  <strong>
                    Distancia .{' '}
                    {games?.docs.length ? `${games.docs[0].score}` : ``}
                  </strong>
                </p>
              </div>
            </div>
            <div className="mini-table">
              {(games?.docs || []).map((game, key) => {
                const { playerId, score } = game;
                const { name } = playerId as IPlayer;
                if (key === 0) {
                  return <div key={key}></div>;
                }
                return (
                  <div className="mini-table__row" key={key}>
                    <p>
                      <strong>{`${key + 1}°`} Lugar</strong>
                    </p>
                    <p>{`${name}`}</p>
                    <p className="mini-table__score">
                      <strong>Distancia . {`${score}`}</strong>
                    </p>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
        <div className="large-container__init large-container__col col-sm-6 col-12">
          <section className="init-container">
            <figure className="init__logo">
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
            <article className="init__article">
              <h1 className="h2">Bienvenido</h1>
              <>
                <p>
                  <span className="material-icons">navigate_next</span> Hole in
                  one ={' '}
                  <ReactMarkdown
                    skipHtml={false}
                  >{`${holeInOne}`}</ReactMarkdown>
                </p>
                <p>
                  <span className="material-icons">navigate_next</span> O’yes ={' '}
                  <ReactMarkdown skipHtml={false}>{`${oYes}`}</ReactMarkdown>
                </p>
              </>
              <div className="init__price">
                <p>
                  Costo{' '}
                  <span>
                    <ReactMarkdown
                      skipHtml={false}
                    >{`${gameAmount}`}</ReactMarkdown>
                  </span>
                </p>
                <Link to="/init" className="btn btn--type1">
                  Iniciar
                </Link>
              </div>
            </article>
          </section>
        </div>
      </div>
    </section>
  );
};

export default HomeScreen;
