import { encode, ParsedUrlQueryInput } from 'querystring';
import api from './index';
import { IGamePlayed, IIGamePlayedPaginate } from '../types/GamesInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getGamesPlayed = async (
  offset: number,
  limit: number,
  searchText: string,
  token: string
) => {
  const response = await api.get<IIGamePlayedPaginate>(
    `/game-played?${encode({
      limit,
      offset,
      searchText,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getGamePlayedById = async (id: string, token: string) => {
  const response = await api.get<IGamePlayed>(`/game-played/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const createGamePlayed = async (gamePlayed: IGamePlayed) => {
  const response = await api.post<IGamePlayed>('/game-played', gamePlayed);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateGamePlayed = async (
  id: string,
  gamePlayed: Partial<IGamePlayed>,
  token: string
) => {
  const response = await api.patch<IGamePlayed>(
    `/game-played/${id}`,
    gamePlayed,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const deleteGamePlayed = async (id: string, token: string) => {
  const response = await api.delete(`/game-played/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchGamePlayed = async (
  offset: number,
  limit: number,
  sort: string,
  query: Partial<IGamePlayed>
) => {
  const response = await api.post<IIGamePlayedPaginate>(
    `/game-played/search?${encode({
      limit,
      offset,
      sort,
    } as ParsedUrlQueryInput)}`,
    query
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
