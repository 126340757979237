import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format, getUnixTime, subDays } from 'date-fns';
import { searchGamePlayed } from '../../api/gamesApi';
import { IIGamePlayedPaginate } from '../../types/GamesInterface';
import { IPlayer } from '../../types/PlayerInterface';

const ScoreboardScreen = () => {
  const [scroll, setScroll] = useState(false);
  const [games, setGames] = useState<IIGamePlayedPaginate>();
  console.log({ games });

  const getGamesPlayedDocs = async () => {
    try {
      const now = new Date();
      console.log(now.getFullYear(), now.getMonth(), now.getDate());
      const createdAt = getUnixTime(
        subDays(new Date(now.getFullYear(), now.getMonth(), now.getDate()), 30)
      );
      const result = await searchGamePlayed(0, 100, '', {
        createdAt: `${createdAt}`,
      });
      if (result) {
        if (result.docs.length) {
          const gamesPlayed = result.docs.sort((a, b) =>
            a.score > b.score ? -1 : 1
          );
          setGames({
            ...result,
            docs: gamesPlayed,
          });
        }
      }
    } catch (error) {
      console.log('getGamesPlayedDocs', error);
    }
  };

  useEffect(() => {
    getGamesPlayedDocs();
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <section className="container-fluid">
      <div className="row">
        <header
          className={scroll ? 'header-nav col-12 active' : 'header-nav col-12'}
        >
          <Link to="/" className="btn btn--simple-back">
            <span className="material-icons">arrow_back</span> <p>Regresar</p>
          </Link>
        </header>
        <section className="contain-top col-12">
          <div className="score-mini__date score-date--black">
            <p>Hoy {`${format(new Date(), 'dd . MMM . yyy')}`}</p>
            <h3>Score Board</h3>
          </div>
          <div className="score-mini__user-win score-user--black">
            <div className="badge badge--color1">Ganador</div>
            <h6>
              <strong>1ER LUGAR</strong> .{' '}
              <span>
                {' '}
                {games?.docs.length
                  ? `${(games.docs[0].playerId as IPlayer).name}`
                  : ``}
              </span>
            </h6>
            <p className="sore-mini__user-win-name">
              Score {games?.docs.length ? `${games.docs[0].score}` : ``}
            </p>
          </div>
          <div className="mini-table table--dark col-sm-10 col-12 mx-auto mb40">
            {(games?.docs || []).map((game, key) => {
              const { playerId, score } = game;
              const { name } = playerId as IPlayer;
              if (key === 0) {
                return <div key={key}></div>;
              }
              return (
                <div className="mini-table__row" key={key}>
                  <p>
                    <strong>{`${key + 1}°`} Lugar</strong>
                  </p>
                  <p>{`${name}`}</p>
                  <p className="mini-table__score">
                    <strong>Score . {`${score}`}</strong>
                  </p>
                </div>
              );
            })}
          </div>
          {(games?.docs || []).length >= 30 ? (
            <div className="paginator">
              <button className="paginator__move">
                <span className="material-icons">arrow_back_ios</span>
              </button>
              <ul className="paginator__list">
                <li>
                  <button className="active">1</button>
                </li>
                <li>
                  <button>2</button>
                </li>
                <li>
                  <button>3</button>
                </li>
                <li>
                  <button>4</button>
                </li>
                <li>
                  <button>5</button>
                </li>
                <li>
                  <button>...</button>
                </li>
              </ul>
              <button className="paginator__move">
                <span className="material-icons">arrow_forward_ios</span>
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </section>
      </div>
    </section>
  );
};

export default ScoreboardScreen;
