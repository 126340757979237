import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TermsScreen = () => {
  // const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <section className="large-container container-fluid">
      <div className="row">
        <header
          className={scroll ? 'header-nav col-12 active' : 'header-nav col-12'}
        >
          <button onClick={() => navigate(-1)} className="btn btn--simple-back">
            <span className="material-icons">arrow_back</span> <p>Regresar</p>
          </button>
        </header>
        <div className="contain-top col-12">
          <header className="header">
            <figure>
              <img
                src="/assets/images/logo/logo_holy_car.png"
                alt="Holy Car - Takes One Shot"
              />
            </figure>
          </header>
          <section className="game-title">
            <h1>Términos y condiciones</h1>
          </section>
          <section className="content-info col-sm-6 col-7 mx-auto mb40">
            <article>
              <p>El presente documento tiene por objeto establecer los términos y condiciones de los torneos deportivos denominados “A Holy Car” (en adelante, los “Términos y Condiciones”) que la sociedad OPERADORA DEPORTIVA A HOLY CAR, SAPI DE CV, y sus subsidiarias (en adelante, “AHC”) organiza y opera de conformidad con lo establecido en su Objeto Social, y al cual Usted se inscribe por medio de la plataforma digital ubicada en el lugar en donde tiene lugar dicho torneo.</p>

              <p>AHC es una sociedad debidamente constituida conforme a la legislación mexicana, y se encuentra debidamente inscrita en el Registro Público de la propiedad y del Comercio. Así mismo, se encuentra registrada en el Registro Federal de Contribuyentes.</p>

              <p>En este sentido, para todo lo relacionado con los presentes Términos y Condiciones, AHC establece su domicilio en la avenida Homero 229, Piso Master Suite, colonia Polanco, alcaldía de Miguel Hidalgo, CP 11560, Ciudad de México, México.</p>

              <p>Por otro lado, para la debida operación del torneo al cual Usted se inscribe, AHC recabará ciertos datos e información considerada personal, y cuya entrega por parte de Usted es obligatoria y sin excepción. Todos los datos e información recabada por parte de AHC se encontrarán protegidos por lo dispuesto en nuestras Políticas de Privacidad (en adelante referidas indistintamente como “Políticas de Privacidad” o Aviso de Privacidad”), las cuales Usted conoció previamente a su inscripción al torneo, así como previo a la aceptación de los presentes Términos y Condiciones. En el caso en el que Usted no acepte los presentes Términos y Condiciones, o bien, no acepte las Políticas de Privacidad, no deberá inscribirse a ningún torneo operado por AHC.</p>

              <h2>1. Objeto del Servicio</h2>

              <p>AHC organiza y opera torneos deportivos de diferentes características, en instalaciones administradas y propiedad de terceros ajenos a AHC. En el sitio en donde se lleva a cabo cada torneo, AHC pone a su disposición un kiosko con una plataforma digital en donde hace de su conocimiento: (a) el costo de inscripción en el torneo; (b) la fecha, hora y ubicación de su torneo; y (c) el premio o premios disponibles.</p>

              <p>Todos los participantes de los torneos se sujetan de manera obligatoria a los presentes Términos y Condiciones, así como a lo dispuesto en el documento denominado “Reglas Básicas AHC”, mismo que se encuentra disponible para consulta en los kioscos referidos anteriormente.</p>

              <h2>2. Torneos Hoyo en Uno</h2>

              <p>Usted podrá inscribirse en los torneos de “Hoyo en Uno”, los cuales consisten en que Usted y sus acompañantes podrán participar para que, el primero que consiga un hoyo en uno en el hoyo designado para ello, y en el tiempo correspondiente a su torneo, en un solo intento y sujeto a la validación de los Visores, gane el premio disponible.</p>

              <p>Toda inscripción deberá ser completamente pagada previo al inicio del torneo. En ningún caso existirán cambios ni devoluciones. Los torneos de Hoyo en Uno se regirán conforme al reglamento deportivo aplicable al Golf, relativo, precisamente, a los hoyo en uno. Cualquier violación a dicho reglamento, o bien, cualquier violación al reglamento del campo y/o de las instalaciones, o a lo dispuesto en el documento denominado “Reglas Básicas AHC”, invalida su participación en el torneo al que se hubiere inscrito, y en caso de haber ganado cualquier premio, no podrá reclamarlo.</p>

              <h2>3. Torneos O’yes!</h2>

              <p>Usted podrá inscribirse en los torneos de “O’yes!”, los cuales consisten en que Usted y todos los inscritos en dicho torneo, participan para ganar el premio disponible, adjudicable a quien en un solo tiro y de un solo intento, deje su pelota más cerca del hoyo participante, en el tiempo correspondiente a su torneo, y sujeto a la validación de los Visores.</p>

              <p>Toda inscripción deberá ser completamente pagada previo al inicio del torneo. En ningún  caso existirán cambios ni devoluciones. Los torneos de “O’yes!” se regirán conforme a los dispuesto en los presentes Términos y Condiciones, y en lo general a lo dispuesto en el reglamento deportivo aplicable al Golf. No aplica la modalidad conocida como “O’yes sangriento”.</p>

              <p>Cualquier violación al reglamento de Golf, o bien, cualquier violación al reglamento del campo y/o de las instalaciones, o a lo dispuesto en el documento denominado “Reglas Básicas AHC”, invalida su participación en el torneo al que se hubiere inscrito, y en caso de haber ganado cualquier premio, no podrá reclamarlo.</p>

              <h2>4. Visores</h2>

              <p>Con el propósito de garantizar la correcta operación, y adecuada participación de los inscritos en cada torneo, AHC contará con al menos una persona en el sitio del torneo para operar como “Visor”, es decir, como testigo y juez de que la participación de cada uno de los inscritos fue de conformidad con los presentes Términos y Condiciones y al documento denominado “Reglas Básicas AHC”, y que el desempeño y participación de cada inscrito fue como se presume ser. Dicho Visor se apoyará del equipo tecnológico (cámaras) que AHC le proporcione para tal efecto.</p>

              <p>Las cámaras del campo son operadas por Advantage Service Agente de Seguros SA de C.V. y serán utilizadas como testigo única y exclusivamente del torneo denominado “hole in one”. </p>

              <p>Todo material videográfico recopilado es propiedad de advantage service agente de seguros da de C.V.  y en ningún caso los videos serán puestos a disposición de ningún trabajador del campo, participante o personas ajenas a Advantage Service Agente de Seguros S.A. de C.V.</p>

              <p>Los Visores serán la máxima autoridad en campo para validar la participación de cada inscrito, y a la mínima prueba de cualquier violación al reglamento del campo y/o de las instalaciones, al reglamento de Golf, o bien, a los presentes Términos y Condiciones y al documento denominado “Reglas Básicas AHC”, se invalida la participación del infractor en el torneo al que se hubiere inscrito, y en caso de haber ganado cualquier premio, no podrá reclamarlo.</p>

              <p>Cuando existiera cualquier reclamación, el inscrito deberá ponerse en contacto con AHC por escrito, dentro de los 5 (cinco) días hábiles posteriores a la fecha en que se hubieren cometido los hechos, para que un Comité Especial analice y valore las pruebas que para tal efecto proporcione el Visor, con el objeto de tomar una decisión en los siguientes 20 (veinte) días hábiles.</p>

              <p>Todas las decisiones del Comité Especial se consideran irrevocables.</p>

              <h2>5. Entrega de Premios</h2>

              <p>Los participantes de los torneos que hubieren salido victoriosos, y que por ende tuvieran derecho de reclamar el premio disponible, deberán reclamarlo de la siguiente forma:</p>

              <p>(a) Torneos A Hole in One: el participante deberá reclamarlo en el preciso instante en que consiga obtener el primer hoyo en uno de su torneo. Al hacerlo, el visor le entregará un número de premio y los datos de contacto del área correspondiente de AHC. Así mismo, el visor le requerirá al ganador ciertos datos. En los próximos 3 (tres) días hábiles, el ganador deberá reclamar por escrito y en el domicilio de AHC la entrega de su premio. Una vez recibida la reclamación en tiempo y forma, AHC tendrá hasta 120 (ciento veinte) días para hacer la entrega.</p>

              <p>(b) Torneos O’yes!: AHC publicará en el sitio web <a href="http://www.holy-car.com">www.holy-car.com</a> dentro de los 10 (diez) días hábiles posteriores a la conclusión del torneo de que se trate, el nombre o número del participante ganador. En los próximos 5 (cinco) días hábiles, el ganador deberá reclamar por escrito y en el domicilio de AHC la entrega de su premio. Una vez recibida la reclamación en tiempo y forma, AHC tendrá hasta 30 (treinta) días para hacer la entrega.</p>

              <h2>6. Uso de imagen</h2>

              <p>Todos los participantes autorizan a AHC para el uso y explotación de su imagen, referente al material digital que pudiere obtenerse durante su participación en cada torneo. Así mismo, todos los ganadores autorizan a AHC para el uso y explotación de su imagen, referente al material digital que pudiere obtenerse durante la entrega de los premios.</p>

              <p>En este sentido, específicamente los ganadores se obligan a otorgar a AHC el premiso referido anteriormente por escrito, como condición innegociable para la entrega del premio correspondiente.</p>

              <h2>7. Impuestos, Derechos y otras contribuciones</h2>

              <p>Es única responsabilidad de Usted cubrir todos los derechos, impuestos y otros gastos relacionados con su premio. Para el caso de premios relativos a vehículos, Usted se obliga a realizar todos los trámites, obtener todos los permisos y licencias, así como tramitar los emplacamientos, engomados y demás autorizaciones necesarias.</p>

              <p>AHC no es responsable del uso que Usted pueda dar a sus premios. Cuando aplique, Usted deberá efectuar las declaraciones a las autoridades fiscales correspondientes, conforme lo establezca la ley aplicable.</p>

              <h2>8. Autorizaciones debidas</h2>

              <p>Tal y como se ha referido anteriormente, la prestación de servicios a cargo de AHC consiste en la organización de torneos deportivos de diferentes características y alcances. AHC no lleva a cabo campañas o mecánicas de sorteo, ni tampoco juegos de apuesta, por lo cual, no se requiere autorización de autoridad alguna. AHC cuenta con las autorizaciones debidas de las autoridades privadas que regulan y/o administran las instalaciones en donde se lleva a cabo el torneo para el cual Usted se ha inscrito.</p>

              <h2>9. Limitaciones y prohibiciones</h2>

              <p>Queda prohíba la participación de cualquier persona que sea menor de edad, jugador profesional, socio y/o de empleado de cualquier sociedad proveedora de “A Holy Car”, socio y/o empleado de “A Holy Car”, y cualquier otro que establezcan las “Reglas Básicas AHC”.</p>

              <h2>10. Omisiones e interpretación</h2>

              <p>Usted y AHC acuerdan que el cumplimiento de los presentes Términos y Condiciones y del documento denominado “Reglas Básicas AHC” es obligatorio.</p>

              <p>Cuando Usted incumpla con cualquiera de las obligaciones referidas en el presente, libera a AHC de cualquier obligación frente a Usted. Los presentes Términos y Condiciones deben interpretarse conforme a lo dispuesto en el Contrato, así como de conformidad con la legislación mexicana, y en el documento denominado “Reglas Básicas AHC”.</p>

              <p>Las Partes acuerdan someterse a la jurisdicción y competencia de los tribunales de la ciudad de México, renunciando a cualquier otra que les pudiere corresponder por domicilio, o por cualquier otra razón, incluyendo incluyendo la jurisdicción de en donde se encuentre ubicadas las instalaciones en donde se lleve a cabo el torneo en el que Usted participe.</p>
              <p><small>Última Actualización: 12 de octubre de 2022.</small></p>
            </article>
          </section>
        </div>
      </div>
    </section>
  );
};

export default TermsScreen;
