import axios from 'axios';

export default axios.create({
  // baseURL: 'https://api.holy-car.com',
  // baseURL: 'http://localhost:5001',
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
