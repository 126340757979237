import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './pages/Home/home.screen';
import ScoreboardScreen from './pages/Scoreboard/scoreboard.screen';
import InitScreen from './pages/Game/init.screen';
import Rulescreen from './pages/Game/rules.screen';
import PayScreen from './pages/Game/pay.screen';
import SuccessScreen from './pages/Game/success.screen';
import ErrorScreen from './pages/Game/error.screen';
import TermsScreen from './pages/Terms/terms.screen';
import PrivacyScreen from './pages/Privacy/privacy.screen';
import PayEfectivoScreen from './pages/Game/payEfectivo.screen';
import LandingScreen from './pages/Landing/landing.screen';
import LoginAdminScreen from './pages/LoginAdmin/loginAdmin.screen';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { getSession } from './utils/helpers';

function App() {
  const session = getSession();
  console.log(process.env);
  return (
    <main className="app-main">
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        <Route path="/login" element={<LoginAdminScreen />} />
        <Route path="/scoreboard" element={<ScoreboardScreen />} />
        <Route
          path="/homepage"
          element={session ? <HomeScreen /> : <LoginAdminScreen />}
        />
        <Route
          path="/init"
          element={session ? <InitScreen /> : <LoginAdminScreen />}
        />
        <Route
          path="/rules"
          element={session ? <Rulescreen /> : <LoginAdminScreen />}
        >
          <Route
            path=":gamePlayedId"
            element={session ? <Rulescreen /> : <LoginAdminScreen />}
          />
        </Route>
        <Route
          path="/pay"
          element={session ? <PayScreen /> : <LoginAdminScreen />}
        >
          <Route
            path=":gamePlayedId"
            element={session ? <PayScreen /> : <LoginAdminScreen />}
          />
        </Route>
        <Route
          path="/success"
          element={session ? <SuccessScreen /> : <LoginAdminScreen />}
        >
          <Route
            path=":gamePlayedId"
            element={session ? <SuccessScreen /> : <LoginAdminScreen />}
          />
        </Route>
        <Route path="/error" element={<ErrorScreen />} />
        <Route path="/terminos-y-condiciones" element={<TermsScreen />} />
        <Route path="/aviso-de-privacidad" element={<PrivacyScreen />} />
        <Route
          path="/pay-efectivo"
          element={session ? <PayEfectivoScreen /> : <LoginAdminScreen />}
        >
          <Route
            path=":gamePlayedId"
            element={session ? <PayEfectivoScreen /> : <LoginAdminScreen />}
          />
        </Route>
      </Routes>
    </main>
  );
}

export default App;
