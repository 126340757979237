import { encode, ParsedUrlQueryInput } from 'querystring';
import api from './index';
import { IPlayer, IPlayerPaginate } from '../types/PlayerInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getPlayers = async (
  offset: number,
  limit: number,
  searchText: string,
  token: string
) => {
  const response = await api.get<IPlayerPaginate>(
    `/player?${encode({
      limit,
      offset,
      searchText,
    } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPlayerById = async (id: string, token: string) => {
  const response = await api.get<IPlayer>(`/player/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const createPlayer = async (player: IPlayer) => {
  const response = await api.post<IPlayer>('/player', player);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updatePlayer = async (
  id: string,
  player: Partial<IPlayer>,
  token: string
) => {
  const response = await api.patch<IPlayer>(`/player/${id}`, player, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const deletePlayer = async (id: string, token: string) => {
  const response = await api.delete(`/player/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchPlayer = async (
  offset: number,
  limit: number,
  sort: string,
  query: Partial<IPlayer>
) => {
  const response = await api.post<IPlayerPaginate>(
    `/player/search?${encode({
      limit,
      offset,
      sort,
    } as ParsedUrlQueryInput)}`,
    query
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
